import React from "react";

export const Footer = (props) => {
    return (
        <section
            className="footer"
            style={{
                backgroundColor: props.data.data.backgroundColor ?? "",
            }}
            id={props.data ? (props.data.href.substring(1, props.data.href.len)) : ""}
        >
            <div className="footer-container">
                <div className="footer-title">
                    <h2 className="footer-title-text">{props.data.data.title}</h2>
                </div>
                <div className="footer-social">
                    <h2 className="footer-social-title">{props.data.data.titleSocial}</h2>
                    <div className="footer-social-networks">
                        {
                            props.data.data.facebookLink ?
                            <a
                                className="footer-social-network"
                                href={props.data.data.facebookLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="footer-social-network-link"
                                    alt="facebook"
                                    src={props.data.data.facebookIcon}
                                />
                                <p className="footer-social-network-text">{props.data.data.facebookText}</p>
                            </a>
                            :
                            ""
                        }
                        {
                            props.data.data.instagramLink ?
                            <a
                                className="footer-social-network"
                                href={props.data.data.instagramLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="footer-social-network-link"
                                    alt="instagram"
                                    src={props.data.data.instagramIcon}
                                />
                                <p className="footer-social-network-text">{props.data.data.instagramText}</p>
                            </a>
                            :
                            ""
                        }
                        {
                            props.data.data.tiktokLink ?
                            <a
                                className="footer-social-network"
                                href={props.data.data.tiktokLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="footer-social-network-link"
                                    alt="tiktok"
                                    src={props.data.data.tiktokIcon}
                                />
                                <p className="footer-social-network-text">{props.data.data.tiktokText}</p>
                            </a>
                            :
                            ""
                        }
                    </div>
                </div>
                <div className="footer-contact">
                    <a
                        href={"mailto:" + props.data.data.emailText +
                            "?subject=" + props.data.data.emailSubject + "&body=" + props.data.data.emailBody}
                        className="footer-contact-item"
                    >
                        <i className={props.data.data.emailIcon + " footer-contact-item-icon"}></i>
                        <p
                            className="footer-contact-item-text"
                            dangerouslySetInnerHTML={{__html: props.data.data.emailText}}
                        />
                    </a>
                    <a
                        className="footer-contact-item"
                        href={"https://api.whatsapp.com/send?phone=" + props.data.data.whatsappText +
                        "&text=" + props.data.data.whatsappBody}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className={props.data.data.whatsappIcon + " footer-contact-item-icon"}></i>
                        <p className="footer-contact-item-text">{props.data.data.whatsappText}</p>
                    </a>
                </div>
                <div className="footer-version">
                    <p className="footer-version-text">Version {props.data.data.version}</p>
                </div>
            </div>
        </section>
    );
};
