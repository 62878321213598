import { Link } from "react-router-dom";
import React from "react";

export const Header = (props) => {
    const pagesDefinition = require("../data/data.json");
    const optionsToShow = pagesDefinition.pages.filter(page => page.showInMenu === true);
    return (
        <div
            id={props.data ? props.data.href.substring(1, props.data.href.len) : ""}
            className="header"
            style={{
                backgroundColor: props.data.data.backgroundcolor ?? "",
                borderRadius: props.data.data.borderRadiusHeader ?? "",
            }}
        >
            <div className="header-container">
                <div className="header-titleLogo-container">
                    {
                        props.data.data.showTitle ?
                            <Link to={"/"}>
                                <h1
                                    className="header-title-text"
                                    style={{
                                        fontSize: props.data.data.fontSizeTitle ?? "",
                                        color: props.data.data.fontColor ?? "",
                                    }}
                                >
                                    {props.data.data.title}
                                </h1>
                            </Link>
                        :
                        ""
                    }
                    {
                        props.data.data.showLogo ?
                            <a href="/">
                                <img
                                    id="header-logo-only-mobile-scroll"
                                    className="img header-logo-only-mobile"
                                    src={props.data ? props.data.data.logoNav : ""}
                                    alt="Imagen Logo"
                                    height={props.data.data.heightImg ? props.data.data.heightImg : ""}
                                    width ={props.data.data.widthImg ? props.data.data.widthImg : ""}
                                />
                            </a>
                        :
                        ""
                    }
                </div>
                <div className="header-options-container">
                    <nav className="header-options-navbar">
                        <ul className="header-options-navbar-ul">
                            {optionsToShow.map((d, i) => (
                                <li
                                    key={`${d.title}-${i}`}
                                    className="header-options-navbar-ul-li"
                                >
                                    {
                                        d.href.charAt(0) === "#" ?//link en la misma página
                                        <a
                                            className="header-options-navbar-ul-li-link"
                                            href={d.href}
                                        >
                                            <span className="header-options-navbar-ul-li-link-text">{d.title}</span>
                                            {
                                                d.iconHeader ?
                                                <img
                                                    alt={d.title}
                                                    src={d.iconHeader}
                                                    className="img-fluid header-options-navbar-ul-li-link-img"
                                                    width={props.data.data.imgHeaderWidth ?? ""}
                                                    height={props.data.data.imgHeaderHeight ?? ""}
                                                />
                                                :
                                                ""
                                            }
                                        </a>
                                        : //link a otra página
                                        <Link
                                            className="header-options-navbar-ul-li-link"
                                            to={d.href}
                                        >
                                            <span className="header-options-navbar-ul-li-link-text">{d.title}</span>
                                            {
                                                d.iconHeader ?
                                                <img
                                                    alt={d.title}
                                                    src={d.iconHeader}
                                                    className="img-fluid header-options-navbar-ul-li-link-img"
                                                    width={props.data.data.imgHeaderWidth ?? ""}
                                                    height={props.data.data.imgHeaderHeight ?? ""}
                                                />
                                                :
                                                ""
                                            }
                                        </Link>
                                    }
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};
