import React from "react";
import { useEffect } from "react";
export const InfoWithImgList = (props) => {
    const detectScrollInfoWithImgList = () => {
        let elem = document.getElementById(props.data.href.substring(1, props.data.href.len));
        if(!elem){
            return;
        }
        let rectElem = elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemHeader = document.getElementById("infoWithImgList-header");
        if(props.data.data.animationMoreThanOneTime || !elemHeader.classList.contains("animate__fadeIn")){
            if(isVisible){
                elemHeader.classList.remove("animate__fadeOut");
                elemHeader.classList.add("animate__fadeIn");
            }else{
                elemHeader.classList.remove("animate__fadeIn");
                elemHeader.classList.add("animate__fadeOut");
            }
        }
        for(let i = 0; i < props.data.data.list.length; i++){
            let elemList = document.getElementById("infoWithImgList-items-item" + i.toString());
            let rectElemList = elemList.getBoundingClientRect();
            let isVisibleItemList = rectElemList.top < window.innerHeight * 0.99 && rectElemList.bottom >= window.innerHeight * 0.01;
            let elemListText = document.getElementById("infoWithImgList-items-item-text" + i.toString());
            let elemListImg = document.getElementById("infoWithImgList-items-item-img" + i.toString());
            if(props.data.data.animationMoreThanOneTime || !(elemListText.classList.contains("aux_class"))){
                if(isVisibleItemList){
                    console.log("es visible el item numero " + i.toString());
                    if(!elemListText.classList.contains("aux_class")){
                        elemListText.classList.add("aux_class");
                    }
                    elemListText.classList.remove(i % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight");
                    elemListText.classList.add(i % 2 === 0 ? "animate__fadeInRight" : "animate__fadeInLeft");
                    elemListImg.classList.remove(i % 2 === 0 ? "animate__fadeInRight" : "animate__fadeInLeft");
                    elemListImg.classList.add(i % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight");
                }else{
                    elemListText.classList.remove(i % 2 === 0 ? "animate__fadeInRight" : "animate__fadeInLeft");
                    elemListText.classList.add(i % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight");
                    elemListImg.classList.remove(i % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight");
                    elemListImg.classList.add(i % 2 === 0 ? "animate__fadeInRight" : "animate__fadeInLeft");
                }
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollInfoWithImgList);
        return () => {
            window.removeEventListener("scroll", detectScrollInfoWithImgList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section
            className="infoWithImgList"
            id={props.data ? (props.data.href.substring(1, props.data.href.len)) : ""}
            style={{
                backgroundColor: props.data.data.backgroundColor ?? "",
                paddingTop: "110px"
            }}
        >
            <div className="infoWithImgList-container">
                <div
                    className="infoWithImgList-header animate__animated"
                    id="infoWithImgList-header"
                >
                    <h1 className="infoWithImgList-header-title">{props.data.data.title}</h1>
                    <p className="infoWithImgList-header-paragraph">{props.data.data.paragraph}</p>
                </div>
                <div className="infoWithImgList-items">
                    {
                        props.data.data.list.map((d,i)=>(
                            <div
                                className="infoWithImgList-items-item" key={"infoImgList-"+i.toString()}
                                id={"infoWithImgList-items-item" + i.toString()}
                            >
                                {
                                    i % 2 === 0 ?
                                    <div
                                        className="infoWithImgList-items-item-text animate__animated"
                                        id={"infoWithImgList-items-item-text" + i.toString()}
                                    >
                                        <h2 className="infoWithImgList-items-item-text-title">{d.title}</h2>
                                        <p className="infoWithImgList-items-item-text-paragraph">{d.paragraph}</p>
                                        <h4 className="infoWithImgList-items-item-text-titleList">{d.titleSyllabus}</h4>
                                        {
                                            d.orderList ?
                                            <ol className="infoWithImgList-items-item-text-list">
                                                {
                                                    d.syllabus.map((e,j)=>(
                                                        <li key={"info-list"+i.toString()+j.toString()} className="infoWithImgList-items-item-text-infoList">{e}</li>
                                                    ))
                                                }
                                            </ol>
                                            :
                                            <ul>
                                                {
                                                    d.syllabus.map((e,j)=>(
                                                        <li key={"info-list"+i.toString()+j.toString()}>{e}</li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        <p className="infoWithImgList-items-item-text-price">
                                            <span className="infoWithImgList-items-item-text-priceTitle">{d.labelPriceService}</span> {d.priceService}
                                        </p>
                                        {
                                            d.extraData?
                                                <p>{d.extraData}</p>
                                            :
                                            ""
                                        }
                                    </div>
                                    :
                                    ""   
                                }
                                <div
                                    className={"infoWithImgList-items-item-img " + (i % 2 === 0 ? "animate__animated ": "animate__animated ")}
                                    id={"infoWithImgList-items-item-img" + i.toString()}
                                >
                                    <img
                                        alt={d.title + i.toString()}
                                        src={d.img}
                                        className="img-fluid infoWithImgList-items-item-img-img"
                                    />
                                    {
                                        d.linkDetail ?
                                        <button
                                            className="infoWithImgList-items-item-img-button"
                                            onClick={()=>{
                                                window.open(d.linkDetail, "_blank");
                                            }}
                                        >
                                            {d.textButton}
                                        </button>
                                        :
                                        d.whatsappNumber ?
                                        <button
                                            className="infoWithImgList-items-item-img-button"
                                            onClick={()=>{
                                                window.open("https://api.whatsapp.com/send?phone=" + d.whatsappNumber +
                                                            "&text=" + d.whatsappBody, "_blank");
                                            }}
                                        >
                                            {d.textButton}
                                        </button>
                                        :
                                        ""
                                    }
                                </div>
                                {
                                    i % 2 === 0 ?
                                    ""
                                    :
                                    <div
                                        className="infoWithImgList-items-item-text animate__animated "
                                        id={"infoWithImgList-items-item-text" + i.toString()}
                                    >
                                        <h2 className="infoWithImgList-items-item-text-title">{d.title}</h2>
                                        <p className="infoWithImgList-items-item-text-paragraph">{d.paragraph}</p>
                                        {
                                            d.orderList ?
                                            <ol className="infoWithImgList-items-item-text-list">
                                                {
                                                    d.syllabus.map((e,j)=>(
                                                        <li key={"info-list"+i.toString()+j.toString()} className="infoWithImgList-items-item-text-infoList">{e}</li>
                                                    ))
                                                }
                                            </ol>
                                            :
                                            <ul>
                                                {
                                                    d.syllabus.map((e,j)=>(
                                                        <li key={"info-list"+i.toString()+j.toString()}>{e}</li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        <p className="infoWithImgList-items-item-text-price">
                                            <span className="infoWithImgList-items-item-text-priceTitle">{d.labelPriceService}</span> {d.priceService}
                                        </p>
                                        {
                                            d.extraData?
                                                <p>{d.extraData}</p>
                                            :
                                            ""
                                        }
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};
