import React from "react";
import { useEffect } from "react";
export const Profile = (props) => {
    const detectScrollProfile = () => {
        let elem = document.getElementById(props.data.href.substring(1, props.data.href.len));
        if(!elem){
            return;
        }
        let rectElem = elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemText = document.getElementById("profile-text");
        let elemImg = document.getElementById("profile-img");
        if(props.data.data.animationMoreThanOneTime || !elemText.classList.contains("animate__fadeInRight")){
            if(isVisible){
                elemText.classList.remove("animate__fadeOutLeft");
                elemText.classList.add("animate__fadeInRight");
                elemImg.classList.remove("animate__fadeOutRight");
                elemImg.classList.add("animate__fadeInLeft");
            }else{
                elemText.classList.remove("animate__fadeInRight");
                elemText.classList.add("animate__fadeOutLeft");
                elemImg.classList.remove("animate__fadeInLeft");
                elemImg.classList.add("animate__fadeOutRight");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollProfile);
        return () => {
            window.removeEventListener("scroll", detectScrollProfile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section
            className="profile"
            id={props.data ? (props.data.href.substring(1, props.data.href.len)) : ""}
            style={{
                backgroundColor: props.data.data.backgroundColor ?? "",
                paddingTop: "110px"
            }}
        >
            <div className="profile-container">
                <div
                    className="profile-text animate__animated"
                    id="profile-text"
                >
                    <h1 className="profile-text-title">{props.data.data.title}</h1>
                    <p className="profile-text-paragraph">{props.data.data.paragraph}</p>
                    <p className="profile-text-paragraph">{props.data.data.paragraph2}</p>
                </div>
                <div
                    className="profile-img animate__animated"
                    id="profile-img"
                >
                    <img
                        alt={props.data.data.title}
                        src={props.data.data.img}
                        className="profile-img-img"
                    />
                </div>
            </div>
        </section>
    );
};
