import React from "react";

export const FloatingWhatsapp = (props) => {
    return (
        <a
            className="floatingWhatsapp"
            href={"https://api.whatsapp.com/send?phone=" + props.data.data.whatsappNumber +
            "&text=" + props.data.data.whatsappBody}
            target="_blank"
            rel="noreferrer"
        >
            <i className={props.data.data.iconWhatsapp + " floatingWhatsapp-icon"}></i>
        </a>
    );
};
