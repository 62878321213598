/*import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";*/
import { /*BrowserRouter,*/ Routes, Route } from "react-router-dom";
//import { About } from "./components/about";
import { Header } from "./components/header";
import { Presentation } from "./components/presentation";
import { Profile } from "./components/profile";
import { InfoWithImgList } from "./components/infoWithImgList";
import { Testimonies } from "./components/testimonies";
import { Footer } from "./components/footer";
import { FloatingWhatsapp } from "./components/floatingWhatsapp";
/*import { Features } from "./components/features";
import { Services } from "./components/services";
import { CommentFb } from "./components/commentFb";
import { HeroCarousel } from "./components/heroCarousel";
import { Counts } from "./components/counts";
import { OurServices } from "./components/ourServices";
import { Checkout } from "./components/checkout";
import { Cta } from "./components/cta";
import { CartShopping } from "./components/CartShopping";
import { Portfolio } from "./components/portfolio";
import { Team } from "./components/team";
import { Contact } from "./components/contact";
import { AttributesFull } from "./components/attributesFull";
import { AttributesWithImage } from "./components/attributesWithImage";
import { ButtonWithArrow } from "./components/buttonWithArrow";
import { OurAchievments } from "./components/ourAchievments";
import { OurAdvices } from "./components/ourAdvices";
import { TextWithImage } from "./components/textWithImage";
import { OurOfferTime } from "./components/ourOfferTime";
import { Clients } from "./components/clients";
import { LinesWithContent } from "./components/linesWithContent";
import TransferPay from "./components/transferPay";*/
import SmoothScroll from "smooth-scroll";
import JsonData from "./data/data.json";
import "./App.css";
import { CartProvider } from "./context/CartProvider";


/*import { ProductDetails } from "./components/productDetails";
import { Login } from "./components/Login";
import { Register } from "./components/Register";
import { ContactMobile } from "./components/contactMobile";
import { PostulationsProperties } from "./components/PostulationsProperties";
import { PublicationsProperties } from "./components/PublicationsProperties";
import { PublicationDetails } from "./components/publicationPropertiesDetails";
import { PostulationDetails } from "./components/postulationPropertiesDetails";
import { StatePostulations } from "./components/statePostulations";*/
//import { AdviceLogout } from "./components/adviceLogout";
import 'animate.css';
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const paginas = [];
  
  for (const item of JsonData.pages) {
    //if (item.pageName === "Navigation") {
    //  {/*paginas.push({pagina: <Navigation/>, orden: item.order});*/ }
    //} else
    if (item.pageName === "Header") {
      paginas.push({ pagina: <Header data={item} key={Math.random()}/>, orden: item.order });
    } /*else if (item.pageName === "About") {
      paginas.push({ pagina: <About data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Features") {
      paginas.push({ pagina: <Features data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Services") {
      paginas.push({ pagina: <Services data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "CommentFb") {
      paginas.push({ pagina: <CommentFb data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Footer") {
      paginas.push({ pagina: <Footer data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "FooterGeogart") {
      paginas.push({ pagina: <FooterGeogart data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "HeroCarousel") {
      paginas.push({ pagina: <HeroCarousel data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Counts") {
      paginas.push({ pagina: <Counts data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "OurServices") {
      paginas.push({ pagina: <OurServices data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Cta") {
      paginas.push({ pagina: <Cta data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Contact") {
      paginas.push({ pagina: <Contact data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Portfolio") {
      paginas.push({ pagina: <Portfolio data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Team") {
      paginas.push({ pagina: <Team data={item} key={Math.random()}/>, orden: item.order });
    } */else if (item.pageName === "Presentation") {
      paginas.push({
        pagina: <Presentation data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "Profile") {
      paginas.push({
        pagina: <Profile data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "InfoWithImgList") {
      paginas.push({
        pagina: <InfoWithImgList data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "Testimonies") {
      paginas.push({
        pagina: <Testimonies data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "Footer") {
      paginas.push({
        pagina: <Footer data={item} key={Math.random()}/>,
        orden: item.order
      });
    } /* else if (item.pageName === "LinesWithContent") {
      paginas.push({
        pagina: <LinesWithContent data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "AttributesFull") {
      paginas.push({
        pagina: <AttributesFull data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "AttributesWithImage") {
      paginas.push({
        pagina: <AttributesWithImage data={item} key={Math.random()}/>,
        orden: item.order,
      });
    } else if (item.pageName === "CartShopping") {
      paginas.push({ pagina: <CartShopping data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "ButtonWithArrow") {
      paginas.push({ pagina: <ButtonWithArrow data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "OurAchievments") {
      paginas.push({ pagina: <OurAchievments data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "OurAdvices") {
      paginas.push({ pagina: <OurAdvices data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "TextWithImage") {
      paginas.push({ pagina: <TextWithImage data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "OurOfferTime") {
      paginas.push({ pagina: <OurOfferTime data={item} key={Math.random()}/>, orden: item.order });
    } else if (item.pageName === "Clients") {
      paginas.push({ pagina: <Clients data={item} key={Math.random()}/>, orden: item.order });
    }*/
}




  return (
    
        <CartProvider>
          {/* <div>{paginas.sort((a, b) => a.orden - b.orden).map((e) => e.pagina)}</div> */}
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  {paginas
                    .sort((a, b) => a.orden - b.orden)
                    .map((e) => e.pagina)}
                </div>
              }
            />
            {/*
            <Route path="/checkout/" element={<Checkout />} />
            <Route path="/checkout/transfer" element={<TransferPay />} />
            <Route path="/productDetails" element={<ProductDetails />} />
            <Route path="/publications" element={<PublicationsProperties />} />
            <Route path="/publication/details" element={<PublicationDetails />}/>
            <Route path="/postulations" element={<PostulationsProperties />} />
            <Route path="/postulation/details" element={<PostulationDetails />}/>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/contacto" element={<ContactMobile />} />
            <Route path="/statePostulations" element={<StatePostulations data={JsonData.pages.filter(dat=>dat.pageName==="StatePostulations")[0]} />} />
            */}
          </Routes>
          <FloatingWhatsapp data={JsonData.pages.filter(dat=>dat.pageName==="FloatingWhatsapp")[0]}/>
          {/*<AdviceLogout data={JsonData.pages.filter(dat=>dat.pageName==="AdviceLogout")[0]}/>*/}
        </CartProvider>

  );
};

export default App;
