import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Testimonies = (props) => {
    const responsive ={
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 800 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
        }
    }
    const detectScrollTestimonies = () => {
        let elem = document.getElementById(props.data.href.substring(1, props.data.href.len));
        if(!elem){
            return;
        }
        let rectElem = elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemContainer = document.getElementById("testimonies-container");
        if(props.data.data.animationMoreThanOneTime || !elemContainer.classList.contains("animate__fadeIn")){
            if(isVisible){
                elemContainer.classList.remove("animate__fadeOut");
                elemContainer.classList.add("animate__fadeIn");
            }else{
                elemContainer.classList.remove("animate__fadeIn");
                elemContainer.classList.add("animate__fadeOut");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollTestimonies);
        return () => {
            window.removeEventListener("scroll", detectScrollTestimonies)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section
            className="testimonies"
            style={{
                backgroundColor: props.data.data.backgroundColor ?? "",
                paddingTop: "110px"
            }}
            id={props.data ? (props.data.href.substring(1, props.data.href.len)) : ""}
        >
            
            <div
                className="testimonies-container animate__animated"
                id="testimonies-container"
            >
                <div className="testimonies-header">
                    <h1 className="testimonies-header-title">{props.data.data.title}</h1>
                    <p className="testimonies-header-paragraph">{props.data.data.paragraph}</p>
                </div>
                <div className="testimonies-container-carousel">
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        draggable={false}
                    >
                        {
                        props.data.data.list.map((d,j)=>(
                            <div className="testimonies-carousel-item" key={"testimonies-"+j.toString()}>
                                {
                                    d.hasVideo ?
                                    <video
                                        controls
                                        className="testimonies-carousel-item-video"
                                    >
                                        <source src={d.src} type="video/mp4"/>
                                    </video>
                                    :
                                    <img
                                        src={d.src}
                                        alt={"testimonios-" + j.toString()}
                                        className="testimonies-carousel-item-img"
                                    />
                                }
                                <div className="testimonies-carousel-item-info">
                                    <h4 className="testimonies-carousel-item-info-name">{d.title}</h4>
                                    <p className="testimonies-carousel-item-info-description">{d.paragraph}</p>
                                </div>
                            </div>
                        ))
                        }
                    </Carousel>
                </div>
    	    </div>
        </section>
    );
};
