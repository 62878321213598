import React from "react";
import { useEffect } from "react";
export const Presentation = (props) => {
    const detectScrollPresentation = () => {
        let elem = document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        if(!elem){
            return;
        }
        let rectElem = elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemTitle = document.getElementById("presentation-info-title");
        let elemSubTitle = document.getElementById("presentation-info-subTitle");
        if(props.data.data.animationMoreThanOneTime || !elemTitle.classList.contains("animate__fadeIn")){
            if(isVisible){
                elemTitle.classList.remove("animate__fadeOut");
                elemTitle.classList.add("animate__fadeIn");
                elemSubTitle.classList.remove("animate__fadeOut");
                elemSubTitle.classList.add("animate__fadeIn");
            }else{
                elemTitle.classList.remove("animate__fadeIn");
                elemTitle.classList.add("animate__fadeOut");
                elemSubTitle.classList.remove("animate__fadeIn");
                elemSubTitle.classList.add("animate__fadeOut");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollPresentation);
        return () => {
            window.removeEventListener("scroll", detectScrollPresentation)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <div
            className="presentation"
            id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
            style={{
                backgroundColor: props.data.data.backgroundColor ?? ""
            }}
        >
            <div
                className="presentation-container"
            >
                {
                    props.data.data.hasVideo ?
                    <video
                        alt={props.data.data.title}
                        id="videoWelcome"
                        autoPlay={true}
                        loop={true}
                        muted
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            minHeight: props.data.data.maxHeight ?? "500px",
                            objectFit: "cover",
                            filter: props.data.data.filter ?? "grayscale(50%)",
                        }}
                    >
                        <source src={props.data.data.video} type="video/mp4" />
                    </video>
                    : props.data.data.img ?
                    <img
                        src={props.data.data.img}
                        alt={""}
                        id="imgWelcome"
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "",
                            filter: props.data.data.filter ?? "grayscale(50%)",
                        }}
                    />
                    :
                    ""
                }
                <div className="presentation-info">
                    <h1
                        className="presentation-info-title animate__animated animate__fadeIn"
                        id="presentation-info-title"
                    >
                        {props.data.data.title}
                    </h1>
                    <h3
                        className="presentation-info-subTitle animate__animated animate__fadeIn"
                        id="presentation-info-subTitle"
                    >
                        {props.data.data.subTitle}
                    </h3>
                    <a
                        className="presentation-info-arrow"
                        href={props.data.data.hrefArrow}
                    >    
                        <i className={props.data.data.iconArrow + " presentation-info-arrow-icon"}></i>
                        <p className="presentation-info-arrow-text">{props.data.data.textArrow}</p>   
                    </a>
                </div>
            </div>
        </div>
    );
};
